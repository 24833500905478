<template>
  <div class="sales">
    <Brand />
    <BackBtn
        @click="back()"
        title="Zum Autohaus" />
    <svg
        viewBox="0 0 825 103.6"
        class="footer">
      <polygon class="st0" points="-5.5,31 234.4,96.5 836.4,29.8 836.4,116.3 -5.5,117.4" />
      <polygon class="st1" points="826.8,75.7 836.4,75.7 836.4,9.2 234.4,55.9 -5.5,10.4 -5.5,76.8 7.9,76.8 109,224.3 286,287.3 573,217.3 856,313.3" />
      <polygon class="st1" points="-39.3,218.8 -48.9,218.8 -48.9,285.3 553.1,238.6 793,284.1 793,217.7 779.6,217.7 678.5,70.2 501.5,7.2 214.5,77.2 -68.5,-18.8" />
      <polygon class="st1" points="-90,-2.9 609,93.1 828,45.1 914,58.1 865,167.1 530,173.1 366,210.1 23,71.1" />
    </svg>
    <div class="map">
      <img src="/static/img/zukunftswerkstatt_top.jpg" ref="map">
      <Touchpoint
          v-for="(process, idx) in state.processes"
          :style="{ animationDelay: `${idx * 0.25}s` }"
          :key="`tp${process.id}`"
          :touchpoint="process"
          @click="viewProcess(process.id)" />
    </div>
    <h1 class="order">
      Sales
      <br><small>Wählen Sie einen Prozessschritt!</small>
    </h1>
  </div>
</template>


<script>
import { ref, onMounted, getCurrentInstance, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import Brand from '@/components/Brand'
import BackBtn from '@/components/BackBtn'
import Touchpoint from '@/components/Touchpoint'

export default {
  name: 'Sales',

  components: {
    Brand,
    BackBtn,
    Touchpoint
  },

  setup () {
    const instance = getCurrentInstance()
    const emitter  = instance.appContext.config.globalProperties.emitter
    const axios    = instance.appContext.config.globalProperties.axios
    const router   = useRouter()
    const route    = useRoute()
    const map      = ref(null)

    const state = reactive({
      processes: []
    })

    const init = () => {
      setMapSize()
      getProcesses('sales')
    }

    const setMapSize = () => {
      if (!map.value) return
      let height = window.innerHeight
      let width  = height * 1.6
      if (width < window.innerWidth) height = window.innerWidth/1.6
      map.value.style.height = `${height}px`
    }

    const getProcesses = async (area) => {
      emitter.emit('loading-start')
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}processes`)
        .then(response => {
          state.processes = response.data.filter(process => process.area === area)
          createToast(
            'Prozessschritte geladen!',
            { type: 'default', position: 'bottom-right', showIcon: true }
          )
          emitter.emit('loading-stop')
        })
        .catch(() => {
          createToast(
            {
              title: 'Prozessschritte konnten nicht geladen werden!'
            },
            {
              type: 'danger',
              position: 'bottom-right',
              showIcon: true
            }
          )
          emitter.emit('loading-stop')
        })
    }

    const viewProcess = (pid) => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Prozessschritt',
        params: {
          section: 'sales',
          pid
        },
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    const back = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Autohaus',
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    window.onresize = () => setMapSize()
    onMounted(() => init())

    return {
      back,
      map,
      state,
      viewProcess
    }
  }
}
</script>


<style lang="scss" scoped>
.sales {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .map {
    position: fixed;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg.footer {
    position: fixed;
    bottom: 0;
    pointer-events: none;
    z-index: 2;

    .st0{
      fill: #5b6e7f;
      opacity: 1;
    }

    .st1{
      opacity: 0.25;
      fill: #5b6e7f;
    }
  }

  .order {
    position: fixed;
    z-index: 10;
    left: 1.5rem;
    bottom: 3.5rem;
    color: #fff;
    font-weight: 600;
    font-size: 2.75rem;
    line-height: 0.5em;

    small {
      font-weight: 700;
      font-size: 1rem;
      text-transform: uppercase;
      color: #a0cbdf;
    }
  }
}
</style>
